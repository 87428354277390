import { ReactComponent as ThreatList } from "../Assests/SVG/OthersMixed/threatList.svg";
import { ReactComponent as TaskList } from "../Assests/SVG/OthersMixed/task-square.svg";
import { ReactComponent as Dashboard } from "../Assests/SVG/OthersMixed/Dashboard.svg";
import { ReactComponent as IntelFlow } from "../Assests/SVG/OthersMixed/IntelFlow.svg";
import { ReactComponent as Indicators } from "../Assests/SVG/OthersMixed/Indicators.svg";
import { ReactComponent as Support } from "../Assests/SVG/OthersMixed/message-question.svg";
import { ReactComponent as Setting } from "../Assests/SVG/OthersMixed/setting.svg";
import { ReactComponent as MyWorkspace } from "../Assests/SVG/OthersMixed/my-workspace.svg";
import { ReactComponent as ThreatProfile } from "../Assests/SVG/OthersMixed/threat-profileSVG.svg";
import { ReactComponent as TestScenario } from "../Assests/SVG/OthersMixed/navigation-ts.svg";
import { ReactComponent as ThreatScenario } from "../Assests/SVG/OthersMixed/threatScenario.svg";

export const sideBarList = [
  {
    id: "threat-informed",
    header: "Threat Informed",
    headerIcon: <ThreatList />,
    href: "",
    children: [
      {
        id: "dashboard",
        header: "Dashboard",
        headerIcon: <Dashboard />,
        href: "/dashboard",
      },
      {
        id: "intel-flow",
        header: "Intel Flow",
        headerIcon: <IntelFlow />,
        href: "/intel-flow",
      },
      {
        id: "indicators",
        header: "Indicators",
        headerIcon: <Indicators />,
        href: "/indicators",
      },
    ],
  },
  {
    id: "threat-scenario",
    header: "Threat Scenario",
    headerIcon: <ThreatScenario />,
    href: "/threat-scenario",
    children: [],
  },
  {
    id: "test-scenario",
    header: "Campaign",
    headerIcon: <TestScenario />,
    href: "/campaign",
    children: [],
  },
  {
    id: "my-task",
    header: "My Tasks",
    headerIcon: <TaskList />,
    href: "/my-tasks",
    children: [],
  },
  {
    id: "my-workspace",
    header: "My Workspace",
    headerIcon: <MyWorkspace />,
    href: "/my-workspace",
    children: [],
  },
  {
    id: "threat-profile",
    header: "Threat Profile",
    headerIcon: <ThreatProfile />,
    href: "/threat-profile",
    children: [],
  },
  {
    id: "settings",
    header: "Settings",
    headerIcon: <Setting />,
    href: "/settings",
    children: [],
  },
  {
    id: "support",
    header: "Support",
    headerIcon: <Support />,
    href: "/support",
    children: [],
  },
];
