import React from "react";
import MarkdownPreview from "@uiw/react-markdown-preview";
import PropTypes from "prop-types";

const CustomMarkdownPreview = ({ source, ...rest }) => {
  return (
    <MarkdownPreview
      source={source}
      className="generated-detail-box"
      wrapperElement={{
        "data-color-mode": "dark",
      }}
      {...rest}
    />
  );
};

CustomMarkdownPreview.propTypes = {
  source: PropTypes.any,
};

export default CustomMarkdownPreview;
