import { createSlice } from "@reduxjs/toolkit";
import {
  createThreatScenario,
  generateAiThreatScenario,
  getAllThreatScenarios,
  getScenarioEntityByID,
  topThreatScenariosTechs,
  updateThreatScenario,
} from "../../../Services/ThreatScenario/threatScenario.service";

const initialState = {
  loading: {
    threatScenarioLoading: false,
    scenarioEntityByIDLoading: false,
    aiGenerativeLoading: false,
  },
  threarScenarioList: [],
  scenarioEntityID: {},
  threatScenarioError: null,
  pagination: {
    scenarioCurrentPage: 0,
    scenarioPageSize: 10,
    scenarioTotalElements: 0,
    scenarioTotalPages: 0,
    scenarioLastPage: false,
  },
  activeTsStep: 0,
  completedTsStep: {},
  requestPayload: {},
  aiRequestPayload: {},
  responseTimeForTs: "",
  topTechniques: {},
};
const threatScenarioSlice = createSlice({
  name: "threatScenario",
  initialState,
  reducers: {
    setActiveTsStep: (state, action) => {
      state.activeTsStep = action.payload;
    },
    setCompletedTsStep: (state, action) => {
      state.completedTsStep = action.payload;
    },
    setRequestPayload: (state, action) => {
      state.requestPayload = action.payload;
    },
    setAiRequestPayload: (state, action) => {
      state.aiRequestPayload = action.payload;
    },
    setResponseTimeForTs: (state, action) => {
      state.responseTimeForTs = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllThreatScenarios.pending, (state) => ({
        ...state,
        loading: {
          ...state.loading,
          threatScenarioLoading: true,
        },
        threatScenarioError: null,
      }))
      .addCase(getAllThreatScenarios.fulfilled, (state, action) => {
        const { response, page, loadmore } = action.payload;
        return {
          ...state,
          loading: {
            ...state.loading,
            threatScenarioLoading: false,
          },
          threarScenarioList: loadmore
            ? [...state.threarScenarioList, ...response.content]
            : response.content,
          threatScenarioError: null,
          pagination: {
            ...state.pagination,
            scenarioCurrentPage: response?.number || page,
            scenarioTotalElements: response?.totalElements,
            scenarioTotalPages: response?.totalPages,
            scenarioLastPage: response?.last,
          },
        };
      })
      .addCase(getAllThreatScenarios.rejected, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          threatScenarioLoading: false,
        },
        threarScenarioList: [],
        threatScenarioError: action.payload,
      }))
      .addCase(getScenarioEntityByID.pending, (state) => ({
        ...state,
        loading: {
          ...state.loading,
          scenarioEntityByIDLoading: true,
        },
      }))
      .addCase(getScenarioEntityByID.fulfilled, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          scenarioEntityByIDLoading: false,
        },
        scenarioEntityID: action.payload,
      }))
      .addCase(getScenarioEntityByID.rejected, (state) => ({
        ...state,
        loading: {
          ...state.loading,
          scenarioEntityByIDLoading: false,
        },
        scenarioEntityID: {},
      }))
      .addCase(generateAiThreatScenario.pending, (state) => ({
        ...state,
        loading: {
          ...state.loading,
          aiGenerativeLoading: true,
        },
      }))
      .addCase(generateAiThreatScenario.fulfilled, (state, action) => {
        const { responseTimeForTs, ...requestPayload } = action.payload;
        return {
          ...state,
          loading: {
            ...state.loading,
            aiGenerativeLoading: false,
          },
          requestPayload: { ...state.requestPayload, ...requestPayload },
          responseTimeForTs,
        };
      })
      .addCase(generateAiThreatScenario.rejected, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          aiGenerativeLoading: false,
        },
        error: action.payload,
      }))

      .addCase(createThreatScenario.pending, (state) => ({
        ...state,
        loading: {
          ...state.loading,
          threatScenarioLoading: true,
        },
      }))
      .addCase(createThreatScenario.fulfilled, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          threatScenarioLoading: false,
        },
        requestPayload: { ...action.payload },
      }))
      .addCase(createThreatScenario.rejected, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          threatScenarioLoading: false,
        },
        error: action.payload,
      }))

      .addCase(updateThreatScenario.pending, (state) => ({
        ...state,
        loading: {
          ...state.loading,
          threatScenarioLoading: true,
        },
      }))
      .addCase(updateThreatScenario.fulfilled, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          threatScenarioLoading: false,
        },
        requestPayload: { ...action.payload },
      }))
      .addCase(updateThreatScenario.rejected, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          threatScenarioLoading: false,
        },
        error: action.payload,
      }))
      .addCase(topThreatScenariosTechs.pending, (state) => ({
        ...state,
        loading: {
          ...state.loading,
          threatScenarioLoading: true,
        },
      }))
      .addCase(topThreatScenariosTechs.fulfilled, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          threatScenarioLoading: false,
        },
        topTechniques: action.payload,
      }))
      .addCase(topThreatScenariosTechs.rejected, (state) => ({
        ...state,
        loading: {
          ...state.loading,
          threatScenarioLoading: false,
        },
        topTechniques: {},
      }));
  },
});
export const {
  setActiveTsStep,
  setCompletedTsStep,
  setRequestPayload,
  setAiRequestPayload,
  setResponseTimeForTs,
} = threatScenarioSlice.actions;

export default threatScenarioSlice.reducer;

export const getThreatScenarioListData = (state) =>
  state.threatScenario.threarScenarioList;
export const getThreatScenarioLoading = (state) => state.threatScenario.loading;
export const getActiveTsStep = (state) => state.threatScenario.activeTsStep;
export const getCompletedTsStep = (state) =>
  state.threatScenario.completedTsStep;
export const getRequestPayload = (state) => state.threatScenario.requestPayload;
export const getAiRequestPayload = (state) =>
  state.threatScenario.aiRequestPayload;
export const getResponseTimeForTs = (state) =>
  state.threatScenario.responseTimeForTs;
export const getTopTechniques = (state) => state.threatScenario.topTechniques;
