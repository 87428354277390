import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  removeEmptyKeys,
  removeEmptyKeysWithArray,
} from "../../helper/removeEmptyKeysHelper";
import { axiosWrapper } from "../../helper";

export const createThreatScenario = createAsyncThunk(
  "threatScenario/createThreatScenario",
  async ({ requestPayload }, { rejectWithValue }) => {
    try {
      const data = await removeEmptyKeys(requestPayload);
      const cleanrequestPayload = await removeEmptyKeysWithArray(data);
      const response = await axiosWrapper.post(
        `threat-scenario/save`,
        cleanrequestPayload
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.error);
    }
  }
);

export const updateThreatScenario = createAsyncThunk(
  "threatScenario/updateThreatScenario",
  async ({ requestPayload, id }, { rejectWithValue }) => {
    try {
      const data = await removeEmptyKeys(requestPayload);
      const response = await axiosWrapper.put(`threat-scenario/${id}`, data);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.error);
    }
  }
);

export const getAllThreatScenarios = createAsyncThunk(
  "threatScenario/getAllThreatScenarios",
  async ({ page = 0, searchValue }, { rejectWithValue }) => {
    try {
      const params = new URLSearchParams();
      params.append("page", page.toString());
      params.append("size", "10");
      if (searchValue) params.append("text_filter", searchValue);
      params.toString();
      const response = await axiosWrapper.get(`threat-scenario/all?${params}`);
      return {
        response,
        page,
        loadmore: page > 0 && response.content && response.content.length > 0,
      };
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getScenarioEntityByID = createAsyncThunk(
  "threatScenario/getScenarioEntityByID",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.get(`threat-scenario/${id}`);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const generateAiThreatScenario = createAsyncThunk(
  "threatScenario/generateAiThreatScenario",
  async (reqestObject, { rejectWithValue }) => {
    try {
      const startTime = Date.now();
      const response = await axiosWrapper.post(
        `/ai/threat-scenario/ttp`,
        reqestObject
      );
      const endTime = Date.now();
      const responseTime = endTime - startTime;
      const customResponse = await removeEmptyKeys(response);
      return { ...customResponse, responseTime };
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const topThreatScenariosTechs = createAsyncThunk(
  "threatScenario/topThreatScenariosTechs",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.get(
        `/threat-scenario/dashboard/top-techniques`
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);
