import { Amplify } from "aws-amplify";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";
import { CookieStorage } from "aws-amplify/utils";
import moment from "moment/moment";

export const configureAmplify = () => {
  Amplify.configure({
    Auth: {
      Cognito: {
        region: process.env.REACT_APP_AWS_COGNITO_REGION,
        userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
        userPoolClientId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_CLIENT_ID,
        mfa: {
          status: "on",
          totpEnabled: true,
        },
      },
    },
    storage: {},
  });

  const cookieStorage = new CookieStorage({
    expires: moment().add(1, "day").toDate(),
  });

  cognitoUserPoolsTokenProvider.setKeyValueStorage(cookieStorage);
};
